<template>
  <div class="text-white bg-black">
    <div
      class="flex flex-col justify-between max-w-4xl p-2 pt-10 mx-auto md:flex-row md:pt-20"
    >
      <div class="flex flex-col w-full md:w-2/5">
        <Link :href="'/'" class="-ml-6 md:-ml-16 lg:-ml-20">
          <LogoSVGWhite class="mx-auto" />
        </Link>
        <div
          class="flex flex-wrap justify-start w-2/3 mx-auto mt-1 text-xs text-center md:w-full md:pr-12 md:text-left"
        >
          <p>
            Spicygigs is committed to providing creators with the tools to
            thrive. Have questions? Reach out to our support team.
          </p>
        </div>
        <div class="mx-auto mb-2 md:mx-0 md:mb-0">
          <p class="mt-4 text-xs font-semibold">Follow Us On</p>
          <div class="flex flex-row gap-3 mt-2">
            <div
              class="flex items-center justify-center w-8 h-8 transition-colors duration-300 bg-purple-500 rounded-full aspect-square hover:bg-purple-700"
              v-show="false"
            >
              <i class="text-white fa-brands fa-facebook"></i>
            </div>
            <div
              class="flex items-center justify-center w-8 h-8 transition-colors duration-300 bg-purple-500 rounded-full aspect-square hover:bg-purple-700"
            >
              <a href="https://www.instagram.com/myspicygigs/" target="_blank">
                <i class="text-white fa-brands fa-square-instagram"></i>
              </a>
            </div>
            <div
              class="flex items-center justify-center w-8 h-8 bg-purple-500 rounded-full aspect-square"
              v-show="false"
            >
              <i class="text-white fa-brands fa-linkedin"></i>
            </div>
            <div
              class="flex items-center justify-center w-8 h-8 transition-colors duration-300 bg-purple-500 rounded-full aspect-square hover:bg-purple-700"
            >
              <a href="https://x.com/myspicygigs" target="_blank">
                <i class="text-white fa-brands fa-x-twitter"></i>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-row mx-auto text-sm gap-7 md:ml-auto md:mr-0">
        <div class="flex flex-col">
          <p class="mt-2 mb-1 font-semibold md:mb-3 md:mt-0">Company</p>
          <ul>
            <li class="">
              <Link
                class="!text-white hover:!text-white hover:underline"
                href="/welcome-page"
              >
                Welcome
              </Link>
            </li>
            <li class="">
              <Link
                class="!text-white hover:!text-white hover:underline"
                href="/about"
              >
                About
              </Link>
            </li>
        
            <li>
              <Link
                href="/feedback"
                class="!text-white hover:!text-white hover:underline"
              >
                Feedback
              </Link>
            </li>
          </ul>
        </div>
        <div class="flex flex-col">
          <p class="mt-2 mb-1 font-semibold md:mb-3 md:mt-0">Legal</p>
          <ul>
            <li class="">
              <Link
                href="/docs/terms-of-service"
                class="!text-white hover:!text-white hover:underline"
              >
                Terms Of Service
              </Link>
            </li>
            <li>
              <Link
                href="/docs/privacy-policy"
                class="!text-white hover:!text-white hover:underline"
              >
                Privacy Policy
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <hr class="w-5/6 mx-auto mt-10" />
    <p class="my-4 text-sm text-center">
      © {{ currentYear }} Copyright Spicygigs, All Rights Reserved
    </p>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { usePage } from '@inertiajs/vue3';
  import LogoSVGWhite from '@/Components/LogoSVGWhite.vue';
  import { Link } from '@inertiajs/vue3';
  import { menuItems } from '@/constants/menuItems';

  const { props } = usePage();
  const isLoggedIn = computed(() => !!props.auth.user);

  const navLinksForVisitors = [
    { text: 'About Us', href: '/about' },
    { text: 'Profiles', href: '/visitor-profiles-page' },
    { text: 'Gigs', href: '/visitor-gigs-page' },
    { text: 'Messaging', href: '/register?p=messaging' },
    { text: 'Registration', href: '/wairegistertlist' },
    { text: 'Login', href: '/login' }
  ];

  const currentYear = new Date().getFullYear();
</script>

<style scoped></style>
